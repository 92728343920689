import React from "react";
import { Link } from "react-router-dom";

import {
  AiOutlineCar,
  AiOutlineFileText,
  AiOutlineMail,
  AiOutlineYoutube,
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiFillMedicineBox,
  AiOutlineFileProtect,
  AiOutlineLink,
} from "react-icons/ai";

import {
  FaTelegramPlane,
} from "react-icons/fa";

import { BiWrench } from "react-icons/bi";
import { FaRegHandshake } from "react-icons/fa";
//import { BsFileEarmarkSlides } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
//import { MdFiberNew } from "react-icons/md";
//import icEVIN from "../../assets/icons/icEVIN.png";
import "./styles.css";
import { ModalContext } from "../modal";
import modalContents from "../modal-contents";
//import externalURLs from "../../services/external-urls";

/*const TagNew = () => (
  <div style={{ color: "red", marginLeft: 8 }}>
    <MdFiberNew />
  </div>
);*/

export default function MainMenu(props) {
  const modal = React.useContext(ModalContext);

  return (
    <>
    
    <h2>SISU</h2>

      <div className="link-list">
        
        <div
          className="link"
          onClick={() => window.open('https://portal.utfpr.edu.br/noticias/guarapuava/lista-de-espera')}
        >
          <AiOutlineFileProtect /> Lista de Espera
        </div>
        
        <div
          className="link"
          onClick={() => window.open('https://portal.utfpr.edu.br/noticias/geral/estude-na-utfpr/publicada-a-convocacao-da-1a-chamada')}
        >
          <AiOutlineFileProtect /> Convocação da 1ª chamada 2023/1
        </div>

        <div
          className="link"
          onClick={() => window.open('https://acessounico.mec.gov.br/sisu')}
        >
          <AiOutlineLink /> Site do SISU
        </div>

      </div>

      <h2>Usuários</h2>

      <div className="link-list">
        <div
          className="link"
          onClick={() => window.open('http://portal.utfpr.edu.br/alunos')}
        >
          <FiUsers /> Alunos
        </div>
        
        <div
          className="link"
          onClick={() => window.open('http://portal.utfpr.edu.br/comunidade')}
        >
          <FiUsers /> Comunidade
        </div>
        
        <div
          className="link"
          onClick={() => window.open('http://portal.utfpr.edu.br/egressos')}
        >
          <FiUsers  /> Egressos
        </div>

        <div
          className="link"
          onClick={() => window.open('http://www.utfpr.edu.br/noticias/geral/estude-na-utfpr')}
        >
          <FiUsers  /> Futuros alunos
        </div>

        <div
          className="link"
          onClick={() => window.open('http://portal.utfpr.edu.br/servidores/site')}
        >
          <FiUsers  /> Servidores
        </div>

      </div>

      <h2>Redes Sociais</h2>

      <div className="link-list">
        <div
          className="link"
          onClick={() => window.open('https://www.instagram.com/utfprgp/')}
        >
          <AiOutlineInstagram /> Instagram
        </div>

        <div
          className="link"
          onClick={() => window.open('https://www.facebook.com/UtfprGP/')}
        >
          <AiOutlineFacebook /> Facebook
        </div>

        <div
          className="link"
          onClick={() => window.open('https://www.youtube.com/c/UTFPRgp')}
        >
          <AiOutlineYoutube /> YouTube
        </div>

        <div
          className="link"
          onClick={() => window.open('https://t.me/UTFPRGP')}
        >
          <FaTelegramPlane /> Telegram
        </div>

      </div>

      <h2>Geral</h2>

      <div className="link-list">
        <div
          className="link"
          onClick={() => window.open('https://portal.utfpr.edu.br/covid-19/')}
        >
          <AiFillMedicineBox /> Covid 19
        </div>
        
        <div
          className="link"
          onClick={() => window.open('http://www.utfpr.edu.br/campus/guarapuava')}
        >
          <AiOutlineFileText /> Principal
        </div>

        <div
          className="link"
          onClick={() => modal.show(modalContents["stand"])}
        >
          <BiWrench /> Projetos
        </div>

        <Link to="/citytour" className="link">
          <AiOutlineCar /> City Tour
        </Link>

        <div
          href="#"
          className="link"
          onClick={() => modal.show(modalContents["sponsor"])}
        >
          <FaRegHandshake />
          Estude Conosco
        </div>

        <div
          className="link"
          onClick={() => window.open('http://portal.utfpr.edu.br/campus/guarapuava/contatos')}
        >
          <AiOutlineMail /> Contatos
        </div>      
      </div>
    </>
  );
}
