const PostEventMessage = () => (
  <div>
    <h1>Repositório do campus virtual</h1>

    <div style={{ textAlign: "justify" }}>
      <i>
        O <b>Campus Virtual</b> está agora disponível como{" "}
        <b>Software Livre</b>, podendo ser utilizado e modificado livremente
        pela comunidade.
      </i>
    </div>

    <div style={{ textAlign: "right", marginTop: 16 }}>
      <i>Prof. Dr. Andres Jessé Porfirio</i>
    </div>
    <div style={{ textAlign: "right" }}>
      <i>Presidente da Comissão de TI e Desenvolvedor do Campus Virtual.</i>
    </div>

    <div
      style={{
        marginTop: 32,
        textAlign: "center",
      }}
    >
      <a
        style={{
          color: "#395b94",
          textDecoration: "none",
        }}
        href="https://github.com/andresjesse/prototipo-campus-virtual"
        target="_blank"
        rel="noreferrer" 
      >
        https://github.com/andresjesse/prototipo-campus-virtual
      </a>
    </div>
  </div>
);

export default PostEventMessage;
