import sisu from "./sponsor-logos/sisu.png";
import espera from "./sponsor-logos/espera.png";
import civil from "./sponsor-logos/civil.png";
import mecanica from "./sponsor-logos/mecanica.png";
//import tmi from "./sponsor-logos/tmi.png";
import tsi from "./sponsor-logos/tsi.png";

import "./sponsor.css";

const Card = ({ title, logo, url }) => (
  <>
    <h2>{title}</h2>
    <a href={url} target="_blank" rel="noreferrer" className="card">
      <img src={logo} alt="logo do apoiador" className=" global-shadow" />
    </a>
  </>
);

const Sponsor = () => (
  <div className="sponsor-container">
    <h1>Estude Aqui:</h1>

      
    <h1>Cursos:</h1>
      <Card
          title="Engenharia Civil"
          logo={civil}
          url="http://www.utfpr.edu.br/cursos/coordenacoes/graduacao/guarapuava/gp-engenharia-civil/"
        />

        <Card
          title="Engenharia Mecânica"
          logo={mecanica}
          url="http://portal.utfpr.edu.br/cursos/coordenacoes/graduacao/guarapuava/gp-engenharia-mecanica"
        />

        <Card
          title="Tecnologia em Sistemas para Internet"
          logo={tsi}
          url="http://www.utfpr.edu.br/cursos/coordenacoes/graduacao/guarapuava/gp-tecnologia-em-sistemas-para-internet"
        />

        <Card
                  title="Convocação da 1ª chamada 2023/1"
                  logo={sisu}
                  url="https://portal.utfpr.edu.br/noticias/geral/estude-na-utfpr/publicada-a-convocacao-da-1a-chamada"
                />

        <Card
                  title="Lista de Espera"
                  logo={espera}
                  url="https://portal.utfpr.edu.br/noticias/guarapuava/lista-de-espera"
                />
      </div>
);

export default Sponsor;
